import React from "react";

function Footer() {
  return (
    <footer className="py-8 px-8 bg-[#240253]" aria-labelledby="footerHeading">
      <h2
        id="footerHeading"
        className=" text-2xl text-white font-bold text-center tracking-wider"
      >
        Thrivedge Edutech Pvt Ltd
      </h2>
      <div className="flex mt-4">
        <ul className="mx-auto flex space-x-4 text-gray-200">
          <li className="nav-item active">
            <a className="nav-link" href="#home">
              Home
            </a>
          </li>

          <li className="nav-item">
            <a className="nav-link" href="#contact">
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <div className="contact-us space-y-2 my-3 text-white mx-auto max-w-7xl mt-4">
        <h3 className="my-2 text-3xl">Contact Us</h3>
        <div>
          <i className="fa fa-phone" aria-hidden="true"></i> : +91 88886 03789
        </div>
        <div>
          <i className="fa fa-envelope" aria-hidden="true"></i> :
          contact@Instipe.com
        </div>
        <div>
          <i className="fa-regular fa-building" aria-hidden="true"></i> : 4th
          &amp; 5th Floor, DLF Phase-5,Sector-43, Golf Course Rd, Gurugram. DLF
          QE, Gurgaon, Dlf Qe, Haryana, India, 122002
        </div>
      </div>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 text-gray-300 mx-auto max-w-7xl mt-8">
        <div>
          <p>
            Copyright © 2024 Thrivedge Edutech Pvt Ltd. All rights reserved.
          </p>
        </div>

        <div className="col-md-6 text-center lg:text-right">
          <a href="privacy-policy.html" className="text-light">
            Privacy Policy
          </a>
          <span className="mx-2">|</span>
          <a href="terms-and-conditions.html" className="text-light">
            Terms of Service
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import GetStartedButton from "../Button/GetStartedButton.jsx";

function AboutPage() {
  return (
    <div className=" min-h-screen flex items-center  bg-[#240253]">
      <div className="xl:ml-80 mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-[90rem]">
        <div>
          <div className="space-y-8 py-10">
            <div className="space-y-4 w-full max-w-6xl">
              <h5 className="text-[#02fafe] text-4xl text-center md:text-left tracking-wide">
                About Company
              </h5>
              <p className="text-gray-200 text-center w-full md:text-left font-bold text-5xl tracking-wider mt-2">
                Help Every phase of your <br /> bussiness grow
              </p>
              <p className="text-gray-500 font-medium ">
                Instipe's payment gateway redefines efficiency in fee collection
                for schools. Seamlessly secure, it empowers educational
                institutions with real-time tracking and user-friendly
                interfaces, simplifying financial management
              </p>
            </div>
            <div className=" grid grid-cols-1 max-w-5xl gap-x-10 gap-y-8 sm:grid-cols-2">
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Secure Transactions Always
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Real-Time Payment Tracking
                </p>
              </div>

              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  User-Friendly Interface
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Customizable Fee Structures
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Automated Financial Reports
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Diverse Payment Options
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Effortless Reconciliation
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Responsive Customer Support
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Transparent Fee Details
                </p>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 flex-none rounded-full bg-white flex justify-center items-center">
                  <i className="fa-solid fa-check"></i>
                </div>
                <p className="text-[#02fafe] text-2xl ml-3">
                  Seamless Subscription Management
                </p>
              </div>
            </div>
            <GetStartedButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
